import React, { Suspense, lazy } from "react";
import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Toaster } from "react-hot-toast";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import ScrollToTop from "./ScrollToTop/ScrollToTop"; // Import the ScrollToTop component

// Lazy-load components
const AllPage = lazy(() => import("./AllPage"));
const TestRideForm = lazy(() => import("./TestRide/TestRideForm"));
const DealerPage = lazy(() => import("./DealerShip/Dealership"));
const M1Scooter = lazy(() => import("./ExploreScooters/M1/M1Scooter"));
const M2Scooter = lazy(() => import("./ExploreScooters/M2/M2Scooter"));
const M3Scooter = lazy(() => import("./ExploreScooters/M3/M3Scooter"));
const M4Scooter = lazy(() => import("./ExploreScooters/M4/M4Scooter"));
const ScooterBooking = lazy(() => import("./ScootersBooking/ScooterBooking"));
const ScooterData = lazy(() => import("./Hamburgur/ScooterData"));
const BlogPageAll = lazy(() => import("./Blog/BlogFullPage/BlogPageAll"));
const Footer = lazy(() => import("./Footer/Footer"));
const AboutUsFullPage = lazy(() =>
  import("./AboutUs/AboutUsFullPage/AboutUsFuulPage")
);
const WhyFull = lazy(() =>
  import("./WhyChooseMaxtron/WhyChooseMaxtroneFullPage/WhyFull")
);
const DealerLocator = lazy(() => import("./NearestDelear/DealerNetwork"));
const OfferAdd = lazy(() => import("./OfferBanner/Header"));
const Navbar = lazy(() => import("./Navbar/Navbar"));

// Define a Layout component to conditionally render the footer
function Layout({ children }) {
  const location = useLocation(); // Get the current location
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <OfferAdd />
        <Navbar />
        {children}
        {location.pathname !== "/dealer" && <Footer />}
      </Suspense>
    </>
  );
}

function App() {
  return (
    <div className="App">
      <Router>
        <Toaster />
        <ScrollToTop />{" "}
        {/* Ensures that every route opens directly at the top */}
        <Layout>
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/" element={<AllPage />} />
              <Route path="/testride" element={<TestRideForm />} />
              <Route path="/dealer" element={<DealerPage />} />
              <Route path="/m1" element={<M1Scooter />} />
              <Route path="/m2" element={<M2Scooter />} />
              <Route path="/m3" element={<M3Scooter />} />
              <Route path="/m4" element={<M4Scooter />} />
              <Route path="/sbooking" element={<ScooterBooking />} />
              <Route path="/scooterdata" element={<ScooterData />} />
              <Route path="/blogpageall" element={<BlogPageAll />} />
              <Route path="/aboutus" element={<AboutUsFullPage />} />
              <Route path="/whyfull" element={<WhyFull />} />
              <Route path="/dealerlocator" element={<DealerLocator />} />
            </Routes>
          </Suspense>
        </Layout>
      </Router>
    </div>
  );
}

export default App;
